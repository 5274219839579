body {
	margin: 0;
	padding: 0;
	background-color: #f6f7f7;
	color: #272e46;
	font-family: 'Raleway', sans-serif;
	font-size: 14px;
	line-height: 26px;
	font-weight: 300;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
