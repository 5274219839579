.slick-prev,
.slick-next {
	width: 60px !important;
	height: 60px !important;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
	opacity: .25 !important;
}
.slick-prev:before,
.slick-next:before {
	opacity: 1 !important;
	font-size: 60px !important;
	color: #272e46 !important;
}

.slick-prev {
	left: -60px !important;
}

.slick-next {
	right: -70px !important;
}

.slick-slide div {
	margin: 0 15px;
}

.slick-slide img {
	border-top-width: 5px;
	border-right-width: 5px;
	border-bottom-width: 5px;
	border-left-width: 5px;
	border-left-color: #272e46;
	border-left-style: groove;
	border-right-color: #272e46;
	border-right-style: groove;
	border-top-color: #272e46;
	border-top-style: groove;
	border-bottom-color: #272e46;
	border-bottom-style: groove;
	width: 100%;
	height: auto;
}

.slick-slide:hover img {
	border: 5px solid #00aeef;
}

.slick-track {
	float: left;
}

@media only screen and (max-width: 600px) {
	.slick-prev,
	.slick-next {
		width: 30px !important;
		height: 30px !important;
	}
	.slick-prev:before,
	.slick-next:before {
		font-size: 30px !important;
	}

	.slick-prev {
		left: -20px !important;
	}

	.slick-next {
		right: -30px !important;
	}
}