.field > div {
  margin-bottom: 10px;
}

.field > p {
  margin-top: 0;
  margin-bottom: 8px;
}

.textfield textarea {
  min-height: 100px;
}
